<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>{{
          $t("view.PageBackendTeacher.add_new_title")
        }}</v-card-title>
        <v-card-text>
          <template>
            <FormInputName
              :name="$t('model.prop.name')"
              formKey="name"
              :value="form.name"
              :callbackSelect="entertName"
            />
          </template>
          <template>
            <FormInputMobile
              :name="$t('model.prop.mobile')"
              formKey="mobile"
              :value="form.mobile"
              :callbackSelect="entertMobile"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)">
            {{ $t("action.cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            {{ $t("action.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInputMobile from "@/components/Bread/Teacher/FormInputMobile";
import FormInputName from "@/components/Bread/Teacher/FormInputName";

import { mapState } from "vuex";
export default {
  props: ["self", "role", "model", "url", "action", "callbackSuccess"],
  components: {
    FormInputMobile,
    FormInputName,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      name: null,
      mobile: null,
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    entertName(name) {
      this.form.name = name;
    },
    entertMobile(mobile) {
      this.form.mobile = mobile;
    },

    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
          this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>